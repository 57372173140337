<!-- 竹藤展览 -->
<template>
  <div class="exhibition">
    <el-row>
      <el-col :span="24" :lg="24">
        <div class="pageBox">
          <div class="header pc">
            <el-image
              style="width: 100%"
              :src="require('../../assets/images/exhibition/banner.png')"
              :fit="'fill'"
            >
            </el-image>
            <div class="title " :class="{ weibei: lang }">
              <span v-if="type == 1" class="weibei">{{ $t("img1") }}</span>
              <span v-if="type == 2" class="weibei">{{ $t("img2") }}</span>
              <span v-if="type == 3" class="weibei">{{ $t("img3") }}</span>
              <span v-if="type == 4" class="weibei">{{ $t("img4") }}</span>
            </div>
          </div>
          <div class="header h5" :class="{ weibei: lang }">
            <img
              src="@/assets/images/exhibition/banner-h5.png"
              alt=""
              style="width: 100%; height: 100%; object-fit: cover"
            />
            <div class="title">
              <span v-if="type == 1" class="weibei">{{ $t("img1") }}</span>
              <span v-if="type == 2" class="weibei">{{ $t("img2") }}</span>
              <span v-if="type == 3" class="weibei">{{ $t("img3") }}</span>
              <span v-if="type == 4" class="weibei">{{ $t("img4") }}</span>
            </div>
          </div>
          <div class="imgList">
            <div class="swiper">
              <div class="swiper-container" id="swiperIndex">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="(item, index) in bannerList"
                    :key="index"
                  >
                    <viewer :images="bannerList">
                      <img class="imgBox" :src="item" alt="" />
                    </viewer>
                  </div>
                </div>
                <!-- <div class="swiper-pagination"></div> -->
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Swiper from "swiper"; //引入swiper
import "swiper/css/swiper.css"; //引入swiper样式
export default {
  data() {
    return {
      bannerList: [],
      type: 1,
      list1: [
        require("../../assets/images/imgPages/1/1.jpg"),
        require("../../assets/images/imgPages/1/2.jpg"),
        require("../../assets/images/imgPages/1/3.jpg"),
        require("../../assets/images/imgPages/1/4.jpg"),
        require("../../assets/images/imgPages/1/5.jpg"),
        require("../../assets/images/imgPages/1/6.jpg"),
        require("../../assets/images/imgPages/1/7.jpg"),
        require("../../assets/images/imgPages/1/8.jpg"),
        require("../../assets/images/imgPages/1/9.jpg"),
        require("../../assets/images/imgPages/1/10.jpg"),
      ],
      list2: [
        require("../../assets/images/imgPages/2/1.jpg"),
        require("../../assets/images/imgPages/2/2.jpg"),
        require("../../assets/images/imgPages/2/3.jpg"),
        require("../../assets/images/imgPages/2/4.jpg"),
        require("../../assets/images/imgPages/2/5.jpg"),
        require("../../assets/images/imgPages/2/6.jpg"),
        require("../../assets/images/imgPages/2/7.jpg"),
        require("../../assets/images/imgPages/2/8.jpg"),
        require("../../assets/images/imgPages/2/9.jpg"),
        require("../../assets/images/imgPages/2/10.jpg"),
        require("../../assets/images/imgPages/2/11.jpg"),
        require("../../assets/images/imgPages/2/12.jpg"),
        require("../../assets/images/imgPages/2/13.jpg"),
        require("../../assets/images/imgPages/2/14.jpg"),
        require("../../assets/images/imgPages/2/15.jpg"),
        require("../../assets/images/imgPages/2/16.jpg"),
        require("../../assets/images/imgPages/2/17.jpg"),
        require("../../assets/images/imgPages/2/18.jpg"),
        require("../../assets/images/imgPages/2/19.jpg"),
        require("../../assets/images/imgPages/2/20.jpg"),
        require("../../assets/images/imgPages/2/21.jpg"),
        require("../../assets/images/imgPages/2/22.jpg"),
        require("../../assets/images/imgPages/2/23.jpg"),
      ],
      list3: [
        require("../../assets/images/imgPages/3/1.jpg"),
        require("../../assets/images/imgPages/3/2.jpg"),
        require("../../assets/images/imgPages/3/3.jpg"),
        require("../../assets/images/imgPages/3/4.jpg"),
        require("../../assets/images/imgPages/3/5.jpg"),
        require("../../assets/images/imgPages/3/6.jpg"),
        require("../../assets/images/imgPages/3/7.jpg"),
        require("../../assets/images/imgPages/3/8.jpg"),
        require("../../assets/images/imgPages/3/9.jpg"),
        require("../../assets/images/imgPages/3/10.jpg"),
        require("../../assets/images/imgPages/3/11.jpg"),
        require("../../assets/images/imgPages/3/12.jpg"),
        require("../../assets/images/imgPages/3/13.jpg"),
        require("../../assets/images/imgPages/3/14.jpg"),
        require("../../assets/images/imgPages/3/15.jpg"),
      ],
      list4: [
        require("../../assets/images/imgPages/4/1.jpg"),
        require("../../assets/images/imgPages/4/2.jpg"),
        require("../../assets/images/imgPages/4/3.jpg"),
        require("../../assets/images/imgPages/4/4.jpg"),
        require("../../assets/images/imgPages/4/5.jpg"),
        require("../../assets/images/imgPages/4/6.jpg"),
        require("../../assets/images/imgPages/4/7.jpg"),
        require("../../assets/images/imgPages/4/8.jpg"),
        require("../../assets/images/imgPages/4/9.jpg"),
        require("../../assets/images/imgPages/4/10.jpg"),
        require("../../assets/images/imgPages/4/11.jpg"),
        require("../../assets/images/imgPages/4/12.jpg"),
        require("../../assets/images/imgPages/4/13.jpg"),
        require("../../assets/images/imgPages/4/14.jpg"),
        require("../../assets/images/imgPages/4/15.jpg"),
        require("../../assets/images/imgPages/4/16.jpg"),
        require("../../assets/images/imgPages/4/17.jpg"),
        require("../../assets/images/imgPages/4/18.jpg"),
        require("../../assets/images/imgPages/4/19.jpg"),
        require("../../assets/images/imgPages/4/20.jpg"),
        require("../../assets/images/imgPages/4/21.jpg"),
        require("../../assets/images/imgPages/4/22.jpg"),
        require("../../assets/images/imgPages/4/23.jpg"),
        require("../../assets/images/imgPages/4/24.jpg"),
      ],
    };
  },
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === "zh-CN";
    },
  },
  watch: {
    $route(to, from) {
      console.log(to);
      this.type = this.$route.query.type;
      if (to.path == "/imgPages") {
        switch (this.$route.query.type) {
          case "1":
            this.bannerList = this.list1;
            break;
          case "2":
            this.bannerList = this.list2;
            break;
          case "3":
            this.bannerList = this.list3;
            break;
          case "4":
            this.bannerList = this.list4;
            break;

          default:
            break;
        }
      }
      console.log(this.bannerList);
    },
  },
  created() {
    this.type = this.$route.query.type;
    switch (this.$route.query.type) {
      case "1":
        this.bannerList = this.list1;
        break;
      case "2":
        this.bannerList = this.list2;
        break;
      case "3":
        this.bannerList = this.list3;
        break;
      case "4":
        this.bannerList = this.list4;
        break;

      default:
        break;
    }
  },
  mounted() {
    this.$nextTick(() => {
      var mySwiper = new Swiper("#swiperIndex", {
        //配置分页器内容
        loop: true, // 循环模式选项
        autoplay: false,
        observer: true,
        pagination: {
          el: ".swiper-pagination", //换页器与哪个标签关联
          clickable: true, //分页器是否可以点击
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        //如果需要滚动条
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      });
      console.log(mySwiper);
    });
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="stylus">
.exhibition {
  .pageBox {
    .header {
      .el-image {
        display: block;
      }
    }
  }
}
</style>
<style lang="stylus" scoped>
@media screen and (min-width: 728px) {
  .exhibition {
    background: #fafafa;
    width: 100%;
    overflow: hidden;

    .pageBox {
      .h5 {
        display: none;
      }

      .header {
        position: relative;
        overflow: hidden;

        .title {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          padding: 0 20px;
          min-height: 80px;
          display: flex
          align-items: center
          justify-content: center
          transform: translate(-50%, -50%);
          text-align: center;
          background: rgba(141, 198, 63, 0.9);
          font-weight: 700;
          font-size: 70px;
        }
      }
    }
  }

  .swiper-slide {
    .imgBox {
      height 800px
      display: block;
      margin: auto;
    }
  }

  ::v-deep .swiper-button-prev, .swiper-button-next {
    color: green;
    font-weight: bold
  }
  .swiper-button-next, .swiper-container-rtl .swiper-button-prev{
    right 20vw
  }
  .swiper-button-prev, .swiper-container-rtl .swiper-button-next{
    left: 20vw
  }
}

@media screen and (max-width: 728px) {
  .exhibition {
    width: 100%;
    overflow: hidden;

    p, div {
      line-height: 1.5;
    }

    .pageBox {
      .pc {
        display: none;
      }

      .header {
        position: relative;
        overflow: hidden;
        height: 190px;
        width: 100%;

        .title {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          width: 220px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          transform: translate(-50%, -50%);
          text-align: center;
          background: rgba(141, 198, 63, 0.9);
          font-weight: 700;
          font-size: 18px;
        }
      }
    }
  }

  .swiper-slide {
    .imgBox {
      width: 100%;
      display: block;
      margin: auto;
    }
  }

  ::v-deep .swiper-button-prev, .swiper-button-next {
    color: green;
  }
}
</style>